import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Layout from '../components/Layout/Layout';
import Dossier from '../components/Container/Dossier/Dossier';
import {PageTemplateStyled} from './styles';
import WpRow from '../components/WordPress/WpGrid/WpRow';
import WpRow21 from '../components/WordPress/WpGrid/WpRow_2_1';
import WpRow12 from '../components/WordPress/WpGrid/WpRow_1_2';
import WpRow11 from '../components/WordPress/WpGrid/WpRow_1_1';
import WpColumn from '../components/WordPress/WpGrid/WpColumn';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';

export const DossierPostTemplate = ({
	releaseNumber,
	wordpressId,
	slug,
	tags,
	components,
	excerpt,
	location,
	title,
	date,
	img,
	metaDescription,
}) => {
	const dossierProps = {
		wordpressId,
		slug,
		date,
		title,
		excerpt,
		location,
		img,
		releaseNumber,
		tags,
		components,
		metaDescription,
	};

	return (
		<PageTemplateStyled>
			<Dossier {...dossierProps} />
		</PageTemplateStyled>
	);
};

DossierPostTemplate.propTypes = {
	date: PropTypes.string.isRequired,
	wordpressId: PropTypes.number.isRequired,
	slug: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	excerpt: PropTypes.string,
	metaDescription: PropTypes.string,
	img: PropTypes.shape({
		srcSet: PropTypes.string,
		src: PropTypes.string,
	}),
	releaseNumber: PropTypes.string,

	components: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

DossierPostTemplate.defaultProps = {
	releaseNumber: '',
	relatedContent: [],
	excerpt: '',
	metaDescription: '',
	img: null,
	components: null,
};

const reactComponents = {
	row: WpRow,
	row_2_1: WpRow21,
	row_1_2: WpRow12,
	row_1_1: WpRow11,
	column_1: WpColumn,
	column_2: WpColumn,
};

class DossierPost extends Component {
	/**
	 * Render components dynamically.
	 *
	 * Decides which components to render
	 * based on the data from graphql.
	 */
	renderComponentsDynamically() {
		const {
			data: {wordpressWpNews: post},
		} = this.props;

		const components = post.acf.components_news;
		const {
			acf: {rowLength},
		} = post;

		const componentsObject = {};

		for (let i = 1; i <= rowLength; i += 1) {
			const filteredEntries = components.filter((c) => {
				return c.belongsToRow === i;
			});
			if (filteredEntries && filteredEntries.length > 0) {
				const rowType = filteredEntries[0].layout;
				componentsObject[i] = {
					rowType,
					elements: filteredEntries,
				};
			}
		}
		return Object.keys(componentsObject).map((key) => {
			const c = componentsObject[key];
			const WpComponent = reactComponents[c.rowType];
			return <WpComponent key={key} content={c} />;
		});
	}

	/**
	 * Render post.
	 */
	render() {
		const {
			location,
			data: {wordpressWpNews: post},
		} = this.props;

		const postProps = {
			releaseNumber: post.acf.release_number,
			wordpressId: post.wordpress_id,
			slug: post.slug,
			components: this.renderComponentsDynamically(),
			excerpt: post.excerpt,
			tags: (post.tags && post.tags[0]) || null,
			title: post.title,
			date: post.date,
			img:
				post.featured_media && post.featured_media.localFile
					? post.featured_media.localFile.childImageSharp.fluid
					: null,
			metaDescription: post.yoast_meta.yoast_wpseo_metadesc,
		};

		const trackingProps = {
			wordpressId: post.wordpress_id,
			title: post.title,
			date: post.date,
		};

		return (
			<Layout location={location} trackingProps={trackingProps} className="ap-post">
				<Meta title={appendSeoTitleSuffix(post.title)} />
				<DossierPostTemplate {...postProps} location={location} />
			</Layout>
		);
	}
}

DossierPost.propTypes = {
	data: PropTypes.shape({
		wordpressWpNews: PropTypes.object,
	}).isRequired,
	location: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DossierPost;

export const pageQuery = graphql`
	query DossierPostByID($id: String!) {
		wordpressWpNews(id: {eq: $id}) {
			id
			wordpress_id
			title
			slug
			path
			excerpt
			date(formatString: "MMMM DD, YYYY")
			tags {
				name
			}
			slug
			featured_media {
				localFile {
					childImageSharp {
						id
						fluid(srcSetBreakpoints: [800, 250], quality: 80) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}

			yoast_meta {
				yoast_wpseo_title
				yoast_wpseo_metadesc
			}

			acf {
				hidden
				rowLength
				release_number
				components_news {
					__typename
					... on WordPressAcf_headline {
						belongsToRow
						alignItems
						layout
						column
						as
						text
					}
					... on WordPressAcf_text {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_intro {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_quote {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_spotify {
						belongsToRow
						alignItems
						layout
						column
						playlist
					}

					... on WordPressAcf_infobox {
						belongsToRow
						alignItems
						layout
						column
						theme
						content
					}
					... on WordPressAcf_list {
						belongsToRow
						alignItems
						layout
						column
						as
						items {
							key
							title
							text
						}
					}
					... on WordPressAcf_button {
						belongsToRow
						alignItems
						layout
						column
						button_theme
						link {
							title
							url
							target
						}
					}
					... on WordPressAcf_downloadbutton {
						belongsToRow
						alignItems
						layout
						column
						button_theme
						file {
							title
							filename
							url {
								localFile {
									publicURL
								}
							}
						}
					}
					... on WordPressAcf_image {
						belongsToRow
						alignItems
						layout
						column
						appearance
						alignment
						caption
						optional_link {
							url
						}
						max_width
						image {
							source_url
							alt_text
							title
							localFile {
								childImageSharp {
									id
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on WordPressAcf_accordion {
						belongsToRow
						layout
						column
						stil
						items {
							title
							content {
								content_elements {
									text
									as
									acf_fc_layout
									content
									theme
									appearance
									alignment
									caption
									button_theme
									link {
										title
										url
										target
									}
									optional_link {
										url
									}
									items {
										key
										title
										text
									}
									max_width
									image {
										source_url
										alt_text
										title
										localFile {
											extension
											childImageSharp {
												id
												fluid(srcSetBreakpoints: [800, 250], quality: 80) {
													...GatsbyImageSharpFluid_noBase64
												}
											}
										}
									}
								}
							}
						}
					}
					... on WordPressAcf_releasenumbers {
						belongsToRow
						alignItems
						layout
						column
						content
					}

					... on WordPressAcf_video {
						belongsToRow
						alignItems
						layout
						column
						caption
						video_embed
					}
					... on WordPressAcf_accordion {
						belongsToRow
						layout
						column
						stil
						items {
							title
							content {
								content_elements {
									text
									as
									acf_fc_layout
									content
									theme
									appearance
									alignment
									caption
									button_theme
									link {
										title
										url
										target
									}
									optional_link {
										url
									}
									items {
										key
										title
										text
									}
									max_width
									image {
										source_url
										alt_text
										title
										localFile {
											extension
											childImageSharp {
												id
												fluid(srcSetBreakpoints: [800, 250], quality: 80) {
													...GatsbyImageSharpFluid_noBase64
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
