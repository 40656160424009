import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';

import de from 'date-fns/locale/de';
import {format, parse} from 'date-fns';
import Meta, {appendSeoTitleSuffix} from '../../Layout/Meta';
import {
	ArticleStyled,
	HeadlineStyled,
	LabelStyled,
	ImageStyled,
	HeaderStyled,
	HeaderContentStyled,
	DateStyled,
	IntroStyled,
	ArticleActionsStyled,
	ArticleContentStyled,
	ArticleFooterStyled,
} from '../Article/Article/styles';
import {ResponsiveSwitch} from '../../../utils/responsiveUtils';
import Section from '../../Layout/Section/Section';
import Row from '../../Layout/Grid/Row';
import Column from '../../Layout/Grid/Column';

import ReleaseNumber from '../../Elements/ReleaseNumber/ReleaseNumber';
import ContactSection from '../../Content/ContactSection/ContactSection';

import ShareSocialMedia from '../ShareSocialMedia/ShareSocialMedia';

const Dossier = (props) => {
	const {
		wordpressId,
		slug,
		title,
		releaseNumber,
		components,
		location,
		tags,
		excerpt,
		date,
		img,
		metaDescription,
	} = props;
	const colProps = {
		header: {
			md: 8,
			lg: 6,
			offset: {
				md: 2,
				lg: 3,
			},
		},
		article: {
			md: 10,
			lg: 8,
			offset: {
				lg: 1,
			},
			xxl: 6,
		},
		sidebar: {
			md: 2,
			lg: 1,
			offset: {
				lg: 1,
			},
		},
		footer: {
			lg: 4,
		},
	};

	const dateFormat = parse(date, 'MMMM dd, yyyy', new Date());
	const formattedDate = format(dateFormat, 'd. MMMM yyyy', {locale: de});

	const category = tags.name;

	const label = category ? <LabelStyled text={category} /> : null;

	return (
		<ArticleStyled className="article">
			<div className="category" style={{display: 'none'}}>
				{category}
			</div>
			<Meta title={appendSeoTitleSuffix(title)} description={metaDescription} />

			<Section>
				<HeaderStyled>
					<ImageStyled src={img} />
					<HeaderContentStyled>
						<Section showPadding>
							<Row>
								<Column {...colProps.header}>
									{label}
									<HeadlineStyled itemProp="name" dangerouslySetInnerHTML={{__html: title}} />
								</Column>
							</Row>
						</Section>
					</HeaderContentStyled>
				</HeaderStyled>
			</Section>
			<Section className="content stickArea" showPadding>
				<Row>
					<ResponsiveSwitch>
						{(match) => {
							if (match.md) {
								return (
									<Column {...colProps.sidebar}>
										<Sticky
											topOffset={-80}
											bottomOffset={80}
											boundaryElement=".stickArea"
											hideOnBoundaryHit={false}
											stickyStyle={{marginTop: '60px'}}>
											<ArticleActionsStyled
												slug={slug}
												title={title}
												category={category ? category.name : ''}
												wordpressId={wordpressId}
											/>
										</Sticky>
									</Column>
								);
							}
							// smaller than md
							return null;
						}}
					</ResponsiveSwitch>

					<Column {...colProps.article}>
						<>
							{label}
							<ResponsiveSwitch>
								{(match) => {
									if (match.mdMax) {
										return (
											<HeadlineStyled itemProp="name" dangerouslySetInnerHTML={{__html: title}} />
										);
									}
									// smaller than md
									return null;
								}}
							</ResponsiveSwitch>
							<DateStyled>{formattedDate}</DateStyled>
							<IntroStyled itemProp="description" dangerouslySetInnerHTML={{__html: excerpt}} />
							<ArticleContentStyled>{components}</ArticleContentStyled>
							<ArticleFooterStyled>
								<ReleaseNumber releaseNumber={releaseNumber} />
								<ShareSocialMedia title={title} location={location} />
							</ArticleFooterStyled>
						</>
						<ResponsiveSwitch>
							{(match) => {
								if (match.mdMax) {
									return (
										<Column {...colProps.sidebar}>
											<ArticleActionsStyled
												slug={slug}
												title={title}
												category={category ? category.name : ''}
												bottom
												wordpressId={wordpressId}
											/>
										</Column>
									);
								}
								// smaller than md
								return null;
							}}
						</ResponsiveSwitch>
					</Column>
				</Row>
			</Section>

			<ContactSection />
		</ArticleStyled>
	);
};

Dossier.propTypes = {
	wordpressId: PropTypes.number.isRequired,
	slug: PropTypes.string.isRequired,
	date: PropTypes.string,
	title: PropTypes.string,
	excerpt: PropTypes.string,
	metaDescription: PropTypes.string,
	img: PropTypes.shape({
		srcSet: PropTypes.string,
		src: PropTypes.string,
	}),
	releaseNumber: PropTypes.string,
	components: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

Dossier.defaultProps = {
	date: null,
	title: '',
	excerpt: '',
	metaDescription: '',
	img: null,
	releaseNumber: null,
};

/** @component */
export default Dossier;
