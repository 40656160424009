import styled from 'styled-components';
import {colors, fonts, sizes} from '../../../../styles/variables';
import {HeadlineStyledH1} from '../../../Elements/Headline/styles';
import Label from '../../../Elements/Label/Label';

import Image from '../../../Elements/Image/Image';
import {imageGradient, media, withUnit} from '../../../../styles/utils';
import ArticleActionsSingleArticle from '../ArticleActions/ArticleActionsSingleArticle';

export const ArticleStyled = styled.div`
	img {
		max-width: 100%;
		height: auto;
	}
`;

export const ArticleContentStyled = styled.div`
	.ap-image {
		margin-bottom: ${withUnit(sizes.distance.base)};

		${media.md`
			margin-bottom: ${withUnit(sizes.distance.base * 1.5)};
		`};
	}
`;

export const ArticleFooterStyled = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	flex-direction: column;
	${media.md`
		flex-direction: row;
	`};
`;

export const ArticleActionsStyled = styled(ArticleActionsSingleArticle)`
	margin-top: ${withUnit(sizes.distance.base / 3)};

	${media.md`
		position: sticky;
		top: 80px;
		padding-top: 44px;
		flex-direction: column;
	`};
`;

export const DateStyled = styled.time`
	margin-bottom: 1em;
	color: ${colors.silver};
	font-size: 0.65em;
	font-style: italic;
	font-weight: 400;
	text-transform: uppercase;
	font-family: ${fonts.serif};
`;

export const IntroStyled = styled.div`
	font-family: ${fonts.serif};
	font-style: italic;
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.7;
	margin-bottom: ${withUnit(sizes.distance.base * 1.5)};
`;

export const HeadlineStyled = styled(HeadlineStyledH1)`
	display: none;
	color: ${colors.white};

	${media.md`
		display: block;
	`};

	.content & {
		display: block;
		color: ${colors.black};

		${media.md`
			display: none;
		`};
	}
`;

export const LabelStyled = styled(Label)`
	display: none;
	color: ${colors.white};

	${media.md`
		display: block;
	`};

	.content & {
		display: block;
		color: ${colors.black};

		${media.md`
			display: none;
		`};
	}

	${media.md`
		font-size: 1em;
	`};

	${media.lg`
		font-size: 0.7em;
	`};
`;

export const ImageStyled = styled(Image)`
	position: relative;
	${imageGradient()};

	${media.lg`
		width: 75%;
		margin: auto;
	`};

	img {
		display: block;
	}
`;

export const HeaderStyled = styled.div`
	position: relative;
`;

export const HeaderContentStyled = styled.div`
	box-sizing: border-box;
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: flex-end;

	color: ${colors.white};
`;
