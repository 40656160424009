/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import React from 'react';
import {mediaTypes} from '../styles/utils';

export const initThrottledResizeEvents = () => {
	const throttle = function(type, name, obj) {
		if (typeof window === 'object') {
			obj = obj || window;
			let running = false;
			const func = function() {
				if (running) {
					return;
				}
				running = true;
				requestAnimationFrame(function() {
					let event = null;
					if (typeof CustomEvent === 'function') {
						event = new CustomEvent(name);
					} else {
						event = document.createEvent('Event');
						event.initEvent(name, true, true);
					}
					obj.dispatchEvent(event);
					running = false;
				});
			};
			obj.addEventListener(type, func);
		}
	};
	throttle('resize', 'throttledResize');
};

if (typeof window === 'object') {
	initThrottledResizeEvents();
}

export const matchMedia = () =>
	Object.entries(mediaTypes).reduce((matches, [mediaType, mediaQuery]) => {
		if (window.matchMedia(mediaQuery).matches) {
			return {
				...matches,
				[mediaType]: mediaQuery
			};
		}
		return matches;
	}, {});

export class ResponsiveSwitch extends React.Component {
	state = {matches: {}};

	onResize = () => {
		if (!window.matchMedia) {
			this.setState({matches: {}});
			return;
		}
		this.setState({
			matches: matchMedia()
		});
	};

	componentDidMount = () => {
		window.addEventListener('throttledResize', this.onResize);
		this.onResize();
	};

	componentWillUnmount = () => {
		window.removeEventListener('throttledResize', this.onResize);
	};

	render() {
		const {children} = this.props;
		const {matches} = this.state;
		return children && children(matches);
	}
}
