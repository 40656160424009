import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Spring} from 'react-spring/renderprops';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../../state/actions/default';
import {ActionBarStyled, ActionFavoriteStyled, IconStyled, FavoritesStyled} from './styles';
import ProgressCircle from '../../../Elements/ProgressCircle/ProgressCircle';

class ArticleActionsSingleArticle extends Component {
	state = {toggleLike: false, toggleBookmark: false, visibility: false, dur: 800, animTo: true};

	componentDidMount() {
		const {
			wordpressId,
			likes,
			bookmarks,
			user,
			actions: {getLike, getAllLikes, getAllBookmarks},
		} = this.props;
		// get all likes when article is accessed directly via url - there is not yet any state

		if (likes && likes.results.length === 0) {
			getAllLikes();
		}

		if (user && bookmarks && bookmarks.results && bookmarks.results.length === 0) {
			getAllBookmarks();
		}
		getLike(wordpressId);
	}

	clickLike = (e) => {
		e.preventDefault();
		const {
			actions: {postLike, postUnlike},
			wordpressId,
			slug,
			category,
			title,
			likes,
		} = this.props;
		if (likes.likeBeingProcessed) {
			return;
		}
		const likeFiltered = likes.results.find((elem) => elem.post_id === wordpressId);
		likeFiltered.liked === true ? postUnlike(wordpressId) : postLike(wordpressId);
		if (likeFiltered.liked === true) {
			window.dataLayer.push({
				event: 'biib_removeFavorites',
				biib_feedContent: title,
				biib_feedContentCat: category,
				biib_contentUrl: slug,
			});
		} else {
			window.dataLayer.push({
				event: 'biib_addFavorites',
				biib_feedContent: title,
				biib_feedContentCat: category,
				biib_contentUrl: slug,
			});
		}
		this.setState({toggleLike: true});
	};

	clickBookmark = (e) => {
		e.preventDefault();
		const {
			actions: {postBookmark, postUnbookmark, notifyUser},
			wordpressId,
			slug,
			title,
			category,
			bookmarks,
			user,
		} = this.props;
		if (user) {
			if (bookmarks.bookmarkBeingProcessed) {
				return;
			}
			const currentUser = parseInt(user.UserId, 10);

			if (bookmarks.results) {
				const bookmarkFiltered = bookmarks.results.filter((elem) => parseInt(elem.post_id, 10) === wordpressId);
				if (bookmarkFiltered.length > 0) {
					window.dataLayer.push({
						event: 'biib_removeBookmarks',
						biib_feedContent: title,
						biib_feedContentCat: category,
						biib_contentUrl: slug,
					});
					postUnbookmark(currentUser, wordpressId);
				} else {
					window.dataLayer.push({
						event: 'biib_addBookmarks',
						biib_feedContent: title,
						biib_feedContentCat: category,
						biib_contentUrl: slug,
					});
					postBookmark(currentUser, wordpressId);
				}
			} else {
				window.dataLayer.push({
					event: 'biib_addFavorites',
					biib_feedContentCat: category,
					biib_contentUrl: slug,
				});
				postBookmark(currentUser, wordpressId);
			}
			this.setState({toggleBookmark: true});
		} else {
			const error = {text: 'Bitte logge dich ein, um die Lesezeichen Funktion nutzen zu können.', button: 'OK'};
			notifyUser(error);
		}
	};

	stopAnimation = () => {
		const {animTo} = this.state;
		if (animTo === true) {
			this.setState({visibility: false, dur: 0, animTo: false, toggleLike: false, toggleBookmark: false});
		} else {
			this.setState({visibility: true, dur: 800, animTo: true});
		}
	};

	render() {
		const {className, likes, bookmarks, wordpressId, user, bottom} = this.props;
		const {toggleLike, toggleBookmark, visibility, dur} = this.state;
		const self = this;
		let hideOnSpecials = false;
		let isLiked = false;
		if (!likes || likes.articleLike === null || likes.results.length === 0) {
			return null;
		}

		if (typeof window !== 'undefined') {
			const location = window.location.pathname;
			hideOnSpecials = location.includes('/multiple-sklerose');
		}

		const likeFiltered = likes.results.find((elem) => elem.post_id === wordpressId);
		if (likeFiltered && likeFiltered.liked) {
			({liked: isLiked} = likeFiltered);
		}

		let isBookmarked = false;

		if (user && bookmarks.results) {
			const bookmarkFiltered = bookmarks.results.find((elem) => parseInt(elem.post_id, 10) === wordpressId);

			if (!!bookmarkFiltered && wordpressId === parseInt(bookmarkFiltered.post_id, 10)) {
				isBookmarked = true;
			}
		}

		return (
			<ActionBarStyled className={className} hide={hideOnSpecials}>
				<Spring
					onRest={self.stopAnimation}
					config={{duration: dur}}
					toggle={this.toggleLike}
					from={{value: 0}}
					to={{value: toggleLike ? 100 : 0}}>
					{(props) => (
						<ActionFavoriteStyled liked={isLiked} onClick={this.clickLike}>
							<ProgressCircle
								strokeWidth="2"
								sqSize="50"
								visibility={visibility}
								percentage={props.value}>
								<IconStyled name="like" />
							</ProgressCircle>
						</ActionFavoriteStyled>
					)}
				</Spring>

				<FavoritesStyled pushBottom={bottom}>{likes.articleLike} Likes</FavoritesStyled>

				<Spring
					onRest={self.stopAnimation}
					config={{duration: dur}}
					toggle={this.toggleBookmark}
					from={{value: 0}}
					to={{value: toggleBookmark ? 100 : 0}}>
					{(props) => (
						<ActionFavoriteStyled liked={isBookmarked} onClick={this.clickBookmark}>
							<ProgressCircle
								strokeWidth="2"
								sqSize="50"
								visibility={visibility}
								percentage={props.value}>
								<IconStyled name="bookmark" />
							</ProgressCircle>
						</ActionFavoriteStyled>
					)}
				</Spring>

				<FavoritesStyled>{isBookmarked ? 'Gemerkt' : 'Merken'}</FavoritesStyled>
			</ActionBarStyled>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		likes: state.likes,
		bookmarks: state.bookmarks,
		user: state.userManagement.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {actions: bindActionCreators(Actions, dispatch)};
};

ArticleActionsSingleArticle.propTypes = {
	className: PropTypes.string,
	likes: PropTypes.shape(PropTypes.boolean).isRequired,
	actions: PropTypes.objectOf(PropTypes.func).isRequired,
	wordpressId: PropTypes.number.isRequired,
	slug: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	category: PropTypes.string,
	bookmarks: PropTypes.shape(PropTypes.boolean),
	user: PropTypes.shape(PropTypes.object),
	bottom: PropTypes.bool,
};

ArticleActionsSingleArticle.defaultProps = {
	className: 'ap-label',
	category: '',
	bookmarks: null,
	user: null,
	bottom: false,
};

/** @component */
export default connect(mapStateToProps, mapDispatchToProps)(ArticleActionsSingleArticle);
